import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SliceZone from "../components/SliceZone";
import moment from "moment";
import { StickyContainer, Sticky } from "react-sticky";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { ReactComponent as FacebookIcon } from "../images/blogPost/Facebook.svg";
import { ReactComponent as TwitterIcon } from "../images/blogPost/Twitter.svg";
import { ReactComponent as LinkedInIcon } from "../images/blogPost/LinkedIn.svg";
import "../styles/BlogPost.scss";
import { isMobile, isBrowser, isTablet } from "react-device-detect";
import downButton from "../images/blogPost/down.svg";
import heroBackground from "../images/blogPost/blogPostBckgrnd.svg";
import heroBackgroundMob from "../images/blogPost/blogPostBckgrnd_mob.svg";
import { useRef } from "react";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { Helmet } from "react-helmet";

export const BlogPost = ({ data }) => {
  const body = useRef(null);
  gsap.registerPlugin(ScrollToPlugin);
  if (!data) return null;
  const header = data.prismicHeaderitem || {};
  const footer = data.prismicFooteritem || {};
  const { lang, type, url } = header || {};
  const alternateLanguages = header.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  const blogPost = data.allPrismicBlogpost.nodes[0] || {};

  const formatDate = (date) => {
    var dateObj = moment(date, "YYYY-MM-DD");
    return moment(dateObj).format("DD MMMM YYYY");
  };

  const scrollToBody = () => {
    gsap.to(window, { duration: 0.75, scrollTo: body.current });
  };

  const downloadFile = (link) => {
    fetch(link).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "file";
        a.click();
      });
    });
  };

  return (
    <Layout
      activeDocMeta={activeDoc}
      body={header}
      bodyFooter={footer}
      fullPage={false}
      progressBar={true}
    >
      <Helmet title={blogPost.data?.metatitle}>
        {blogPost.data?.metatitle && (
          <meta name="title" content={blogPost.data.metatitle} />
        )}
        {blogPost.data?.metadescription && (
          <meta name="description" content={blogPost.data.metadescription} />
        )}
        {blogPost.data?.metatitle && (
          <meta property="og:title" content={blogPost.data.metatitle} />
        )}
        {blogPost.data?.metadescription && (
          <meta
            property="og:description"
            content={blogPost.data.metadescription}
          />
        )}
      </Helmet>
      <div className="blogPost">
        <div className="blogPost_hero">
          <div className="blogPost_hero_textCont">
            <div className="blogPost_hero_textCont_infoRow">
              <span className="blogPost_hero_textCont_infoRow_category">
                {blogPost.data.category?.text}
              </span>
              <span className="blogPost_hero_textCont_infoRow_date">
                {formatDate(blogPost.data?.date)}
              </span>
            </div>
            <h2 className="blogPost_hero_textCont_text">
              {blogPost.data?.title.text}
            </h2>
            <span className="blogPost_hero_textCont_timeToRead">
              {blogPost.data?.minuteread} minute read
            </span>
            {blogPost.data.document_download?.url && (
              <div className="blogPost_hero_textCont_downloadCont">
                <a
                  href={blogPost.data.document_download?.url}
                  target="_blank"
                  onClick={() =>
                    downloadFile(blogPost.data.document_download?.url)
                  }
                  download
                >
                  <div className="button blogPost_hero_textCont_downloadCont_button">
                    <p>Download</p>
                  </div>
                </a>
              </div>
            )}
            <img
              src={downButton}
              className="blogPost_hero_textCont_downButton"
              alt="smartwealth scroll down icon"
              onClick={scrollToBody}
            />
          </div>
          <img
            src={isBrowser || isTablet ? heroBackground : heroBackgroundMob}
            alt="smartwealth blog hero image"
            className="blogPost_hero_backgroundImg"
          />
        </div>
        <StickyContainer className="blogPost_sticky">
          <div className="blogPost_blogBody" ref={body}>
            <Sticky className="sticky" disableCompensation={true}>
              {({ style }) => {
                return (
                  <div className="blogPost_blogBody_infoCont">
                    <div className="blogPost_blogBody_infoCont_item">
                      <span className="blogPost_blogBody_infoCont_item_label">
                        Author
                      </span>
                      <p className="blogPost_blogBody_infoCont_item_value">
                        {blogPost.data.author?.text}
                      </p>
                    </div>
                    <div className="blogPost_blogBody_infoCont_item">
                      <span className="blogPost_blogBody_infoCont_item_label">
                        Date
                      </span>
                      <p className="blogPost_blogBody_infoCont_item_value">
                        {formatDate(blogPost.data?.date)}
                      </p>
                    </div>
                    <div className="blogPost_blogBody_infoCont_item">
                      <span className="blogPost_blogBody_infoCont_item_label">
                        Category
                      </span>
                      <p className="blogPost_blogBody_infoCont_item_value blogPost_blogBody_infoCont_item_value-purple">
                        {blogPost.data.category?.text}
                      </p>
                    </div>
                    <div className="blogPost_blogBody_infoCont_share">
                      <span className="blogPost_blogBody_infoCont_share_label">
                        Share
                      </span>
                      <div className="blogPost_blogBody_infoCont_share_iconCont">
                        <FacebookShareButton
                          url={`https://smartwealth.ch${blogPost.url.replace(
                            /\?/g,
                            ""
                          )}`}
                          quote={""}
                          hashtag={"#smartwealth"}
                          description={blogPost.data?.title.text}
                          className="blogPost_blogBody_infoCont_share_iconCont_icon"
                        >
                          <FacebookIcon />
                        </FacebookShareButton>
                        <br />
                        <TwitterShareButton
                          title={blogPost.data?.title.text}
                          url={`https://smartwealth.ch${blogPost.url.replace(
                            /\?/g,
                            ""
                          )}`}
                          quote={blogPost.data?.title.text}
                          hashtag={"#smartwealth"}
                          description={blogPost.data?.title.text}
                          className="blogPost_blogBody_infoCont_share_iconCont_icon"
                        >
                          <TwitterIcon />
                        </TwitterShareButton>
                        <br />
                        <LinkedinShareButton
                          title={blogPost.data?.title.text}
                          url={`https://smartwealth.ch${blogPost.url.replace(
                            /\?/g,
                            ""
                          )}`}
                          summary={blogPost.data?.title.text}
                          source="https://smartwealth.ch/"
                        >
                          <LinkedInIcon />
                        </LinkedinShareButton>
                      </div>
                    </div>
                    {blogPost.data.document_download?.url && (
                      <div className="blogPost_blogBody_infoCont_downloadCont">
                        <a
                          href={blogPost.data.document_download?.url}
                          target="_blank"
                          onClick={() =>
                            downloadFile(blogPost.data.document_download?.url)
                          }
                          download
                        >
                          <div className="button blogPost_blogBody_infoCont_downloadCont_button">
                            <p>Download</p>
                          </div>
                        </a>
                      </div>
                    )}
                  </div>
                );
              }}
            </Sticky>
            <div className="blogPost_blogBody_content">
              <img
                className="blogPost_blogBody_content_mainImage"
                src={blogPost.data.mainimage.url}
                alt="smartwealth blog main image"
              />
              <div className="blogPost_blogBody_content_mainTextCont">
                <p>{blogPost.data.maintext.text}</p>
              </div>
              <div className="blogPost_blogBody_content_slices">
                <SliceZone slices={blogPost?.data?.body} />
              </div>
            </div>
          </div>
        </StickyContainer>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query blogPostQuery($url: String, $lang: String) {
    allPrismicBlogpost(filter: { url: { eq: $url } }) {
      nodes {
        url
        type
        lang
        data {
          minuteread
          author {
            text
          }
          category {
            text
          }
          date
          mainimage {
            url
          }
          maintext {
            text
          }
          title {
            text
          }
          metatitle
          metadescription
          document_download {
            url
          }
          body {
            ... on PrismicBlogpostBodyBlogTextsection {
              id
              slice_type
              primary {
                textblock {
                  text
                }
                title1 {
                  text
                }
              }
            }
            ... on PrismicBlogpostBodyBlogMultimedia {
              id
              primary {
                description {
                  text
                }
                image {
                  url
                }
              }
              slice_type
            }
            ... on PrismicBlogpostBodyBlogFormattedtextblock {
              id
              slice_type
              primary {
                formattedtextblock {
                  text
                  html
                }
                title1 {
                  text
                }
              }
            }
            ... on PrismicBlogpostBodyBlogButtoncont {
              id
              slice_type
              primary {
                buttontext {
                  text
                }
                description {
                  text
                }
                title1 {
                  text
                }
                link {
                  url
                }
              }
            }
          }
        }
      }
    }
    prismicHeaderitem(lang: { eq: $lang }) {
      lang
      type
      url
      alternate_languages {
        lang
        type
      }
      data {
        button {
          text
        }
        item1 {
          text
        }
        item2 {
          text
        }
        item3 {
          text
        }
        aboutus {
          aboutus_name {
            text
          }
        }
        item4 {
          text
        }
        item5 {
          text
        }
        item6 {
          text
        }
        item7 {
          text
        }
        solutions {
          solution_name {
            text
          }
        }
        products {
          product_name {
            text
          }
        }
        cookie_consent {
          raw
        }
      }
    }
    prismicFooteritem(lang: { eq: $lang }) {
      url
      type
      lang
      alternate_languages {
        lang
        type
      }
      data {
        about {
          text
        }
        allrights {
          text
        }
        item1 {
          text
        }
        item2 {
          text
        }
        item3 {
          text
        }
        item4 {
          text
        }
        item5 {
          text
        }
        item6 {
          text
        }
        item7 {
          text
        }
        privacy {
          text
        }
        terms {
          text
        }
        clientinformation {
          text
        }
        impressum {
          text
        }
      }
    }
  }
`;

export default BlogPost;
